<template>
  <v-form
    ref="policyForm"
    lazy-validation
    @submit.prevent="updatePolicy()"
  >
    <!-- Effective Date -->
    <v-menu
      v-model="effectiveDate[policy.id]"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="policy.effective_date"
          label="Effective Date"
          prepend-icon="mdi-calendar-clock"
          readonly
          :rules="[required]"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="policy.effective_date"
        no-title
        color="primary"
        @input="effectiveDate[policy.id] = false"
      />
    </v-menu>

    <!-- Expiration Date -->
    <v-menu
      v-model="expirationDate[policy.id]"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="policy.expiration_date"
          label="Expiration Date"
          prepend-icon="mdi-calendar-refresh"
          class="mt-0 pt-0"
          :rules="[required]"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="policy.expiration_date"
        no-title
        color="primary"
        @input="expirationDate[policy.id] = false"
      />
    </v-menu>

    <!-- Binder Date -->
    <v-menu
      v-model="binderDate[policy.id]"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="policy.binder_date"
          label="Binder Date"
          prepend-icon="mdi-calendar-clock"
          class="mt-0 pt-0"
          :rules="[required]"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="policy.binder_date"
        no-title
        color="primary"
        @input="binderDate[policy.id] = false"
      />
    </v-menu>

    <div class="text-right">
      <v-btn
        small
        type="submit"
        color="success"
        :loading="updatingPolicy"
      >
        <v-icon left>
          mdi-update
        </v-icon>
        Update
      </v-btn>
    </div>
  </v-form>
</template>

<script>
  import { Api } from '@/apis'

  export default {
    props: {
      policy: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => ({
      required: value => !!value || 'This field is required.',
      updatingPolicy: false,
      effectiveDate: {},
      expirationDate: {},
      binderDate: {},
    }),

    methods: {
      async updatePolicy () {
        if (this.$refs.policyForm.validate()) {
          this.updatingPolicy = true
          await new Api().put({
            url: 'policies/policy-important-dates/' + this.policy.id,
            request: this.policy,
          })
          this.updatingPolicy = false
        }
      },
    },
  }
</script>
